import React from 'react';
import styled from 'styled-components';
export const SuccessStyles = styled.div`
font-size: var(--paragraphFontSize);
  margin-left: 2vw;
  margin-right: 2vw;
  max-width: 450px;
  margin-top: var(--headerHeight);
  // text-align: center;
  h1 {
    text-align: center;
  }
`;
export default function SuccessPage() {
    return (
        <SuccessStyles>
        <h1>
            SUCCESS!
        </h1>
        <p>Your message has been sent, someone will be in contact soon!</p>
        </SuccessStyles>
    )
}